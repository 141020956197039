.snackbarProvider {
  & .SnackbarContent-root {
    min-width: 300px;
    padding: 0;
    color: white;
  }

  & .SnackbarItem-message {
    padding: 0;
  }

  & .SnackbarItem-action {
    margin-right: 0;
    padding-left: 0;
  }
}
